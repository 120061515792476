import { ethers } from "ethers"
import { DateTime } from "luxon"
import lists from "./sigs/lists.json"
import { ListConfiguration } from "./types"
import { etherToFloat } from "./util"

function getEnvDefault(
  value: string | undefined,
  key: string,
  defaultValue: string
): string {
  if (value === undefined) {
    console.warn(`Using default config ${key}=${defaultValue} ...`)
    return defaultValue
  }
  return value
}

export const PUBLIC_WALLET = "0x0000000000000000000000000000000000000000"

export const OPENSEA_SLUG = getEnvDefault(
  process.env.NEXT_PUBLIC_OPENSEA_SLUG,
  "OPENSEA_SLUG",
  "cockpunch"
)
export const OPENSEA_URL = `https://opensea.io/collection/${OPENSEA_SLUG}`

export const TOTAL_COUNT = parseInt(
  getEnvDefault(process.env.NEXT_PUBLIC_TOTAL_COUNT, "TOTAL_COUNT", "10000"),
  10
)
export const MAX_PER_WALLET = parseInt(
  getEnvDefault(process.env.NEXT_PUBLIC_MAX_PER_WALLET, "MAX_PER_WALLET", "1"),
  10
)

export const NETWORK_ID = parseInt(
  getEnvDefault(process.env.NEXT_PUBLIC_NETWORK_ID, "NETWORK_ID", "1"),
  10
)

export const ALCHEMY_API_KEY = getEnvDefault(
  process.env.NEXT_PUBLIC_ALCHEMY_API_KEY,
  "ALCHEMY_API_KEY",
  "D2nviLWClcpIn2EO1QE9L0uzXwgMdxnC"
)

export const LISTS = (lists as unknown as [ListConfiguration])!.sort(
  (a, b) => a.startsAt - b.startsAt
)

export const FIRST_MINT_DATE = DateTime.fromSeconds(
  Math.min(...LISTS.map((list) => list.startsAt))
)
export const LAST_MINT_ENDS = DateTime.fromSeconds(
  Math.max(...LISTS.map((list) => list.endsAt))
)

export const CONTRACT_ADDRESS = LISTS.map((list) =>
  Object.values(list.signedAllowances)
).find((a) => a.length > 0)![0]!.allowance.target

export const PUBLIC_LIST = LISTS[LISTS.length - 1]
export const PUBLIC_PRICE = etherToFloat(PUBLIC_LIST.unitPrice)

export type KnownListType = "GUARANTEED" | "PREREG" | "PUBLIC"

export type ListConfig = {
  title: string
  description: string
}
