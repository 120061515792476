import "react-toastify/dist/ReactToastify.css"
import "../styles/globals.css"

import type { AppProps } from "next/app"
import Script from "next/script"
import { ToastContainer } from "react-toastify"

import { Web3Provider } from "../web3"

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Web3Provider>
      <Component {...pageProps} />
      <ToastContainer />
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-6NVN7TNQ2D"
      />
      <Script
        id="ga_init"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-6NVN7TNQ2D');
            `
        }}
      />
    </Web3Provider>
  )
}

export default MyApp
