import { ethers } from "ethers"
import { useContract } from "wagmi"

import ABI from "../abi"
import { ALCHEMY_API_KEY, CONTRACT_ADDRESS, NETWORK_ID } from "../config"

export const staticProvider = new ethers.providers.AlchemyProvider(
  NETWORK_ID,
  ALCHEMY_API_KEY
)

export function useStaticContract() {
  const contract = useContract({
    address: CONTRACT_ADDRESS,
    abi: ABI,
    signerOrProvider: staticProvider
  })

  return contract
}
