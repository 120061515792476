import { useEffect, useState } from "react"

export const BEGINNING_OF_TIME = 1669072179471

export function useChangeableNow(): [number, (now: number) => void] {
  // Now begins as a time before any mint, to prevent server side
  // rendering changing depending on when the site is pushed.
  const [now, setNowRaw] = useState(BEGINNING_OF_TIME)
  const [hasBeenChanged, setHasBeenChanged] = useState(false)

  useEffect(() => {
    const refreshNow = () => {
      if (!hasBeenChanged) {
        setNowRaw(+new Date())
      }
    }

    refreshNow()
    const timeout = setInterval(refreshNow, 888)

    return () => clearTimeout(timeout)
  }, [])

  const setNow = (value: number) => {
    setNowRaw(value)
    setHasBeenChanged(true)
  }

  return [now, setNow]
}

export default function useNow() {
  const [now, _] = useChangeableNow()
  return now
}
