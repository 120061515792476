import { useState, useEffect, Dispatch, SetStateAction } from "react"

const useLocalStorage = <T>(
  key: string
): [() => T | null, (newValue: T) => void, () => void] => {
  const getCurrentValue = (): T | null => {
    try {
      const maybeString = localStorage.getItem(key)
      return maybeString !== null ? JSON.parse(maybeString) : null
    } catch (error) {
      return null
    }
  }

  const setValue = (newValue: T) => {
    localStorage.setItem(key, JSON.stringify(newValue))
  }

  const removeValue = () => {
    localStorage.removeItem(key)
  }

  return [getCurrentValue, setValue, removeValue]
}

export default useLocalStorage
