import { chain, createClient, WagmiConfig } from "wagmi"

import { ALCHEMY_API_KEY, NETWORK_ID } from "./config"
import { ConnectKitProvider, getDefaultClient } from "connectkit"
import { MintingProvider } from "./hooks/useMinting"

const chains = Object.values(chain).filter((chain) => chain.id == NETWORK_ID)

const client = createClient(
  getDefaultClient({
    chains,
    autoConnect: true,
    appName: "PREMINT Mint",
    alchemyId: ALCHEMY_API_KEY
  })
)

export default function Web3Provider({
  children
}: {
  children: JSX.Element | JSX.Element[]
}) {
  return (
    // ConnectKitProvider options:
    // theme = 'auto' | 'web95' | 'retro' | 'soft' | 'midnight' | // 'minimal'
    //          'rounded' | 'nouns';
    // mode = 'light' | 'dark' | 'auto';
    <WagmiConfig client={client}>
      <ConnectKitProvider mode="dark">
        <MintingProvider>{children}</MintingProvider>
      </ConnectKitProvider>
    </WagmiConfig>
  )
}
