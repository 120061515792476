import React, { useState, useEffect } from "react"

export default function useWindowHasFocus() {
  const [isWindowFocused, setIsWindowFocused] = useState(
    typeof document !== "undefined" && document.hasFocus
      ? document.hasFocus()
      : true
  )

  useEffect(() => {
    function handleWindowFocusChange(event: Event) {
      setIsWindowFocused(event.type === "focus")
    }

    window.addEventListener("focus", handleWindowFocusChange)
    window.addEventListener("blur", handleWindowFocusChange)

    return () => {
      window.removeEventListener("focus", handleWindowFocusChange)
      window.removeEventListener("blur", handleWindowFocusChange)
    }
  }, [])

  return isWindowFocused
}
