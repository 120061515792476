// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

if (process.env.NODE_ENV != "development") {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://effeb827e4904cb1abf090ddbd8bd69d@o297924.ingest.sentry.io/4504102608699392",
    tracesSampleRate: 0.01,
    ignoreErrors: [
      /There was an error while hydrating/,
      /Hydration failed because the initial UI/,
      /Text content does not match server-rendered HTML/,
      "invariant=423", // There was an error while hydrating.
      "invariant=425" // Text content does not match server-rendered HTML...
    ]
  })
}
