import { useEffect, useState } from "react"
import { useAccount } from "wagmi"

export default function useAccountAutoconnect() {
  const { address } = useAccount()
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [address])
  return { address: isClient ? address : undefined }
}
