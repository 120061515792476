import { BigNumber, ethers } from "ethers"
import { DateTime } from "luxon"
import { createContext, useContext, useEffect, useState } from "react"
import { toast, ToastOptions } from "react-toastify"
import { useInterval } from "react-use"
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction
} from "wagmi"

import ABI from "../abi"
import {
  CONTRACT_ADDRESS,
  NETWORK_ID,
  TOTAL_COUNT,
  MAX_PER_WALLET,
  LAST_MINT_ENDS
} from "../config"
import { ListConfiguration, MintAllowance } from "../types"
import useAccountAutoconnect from "./useAccountAutoconnect"
import { useStaticContract } from "./useContract"
import useLists from "./useLists"
import useLocalStorage from "./useLocalStorage"
import useNow from "./useNow"
import useWindowHasFocus from "./useWindowHasFocus"

type Props = {
  children: JSX.Element | JSX.Element[]
}

type UseMinting = {
  activeList?: ListConfiguration
  mintableAllowance?: MintAllowance
  futureMintableNonPublicList?: ListConfiguration
  count: number
  mint?: () => Promise<void>
  isLoading: boolean
  isSuccess: boolean
  isMinting: boolean
  isMintOver: boolean
  isSoldOut: boolean
  hasMintedMax: boolean
}

const Context = createContext({
  count: 0,
  isLoading: true,
  isSuccess: false,
  isMinting: false,
  isMintOver: false,
  isSoldOut: false,
  hasMintedMax: false
})

export default function useMinting(): UseMinting {
  return useContext(Context)
}

export function MintingProvider(props: Props) {
  const { children } = props

  const [count, setCount] = useState<number | null>(null)
  const [mintedCount, setMintedCount] = useState<number | null>(null)

  const now = useNow()
  const availableLists = useLists()

  const activeLists = availableLists
    .sort((a, b) => b.list.startsAt - a.list.startsAt)
    .filter((l) => l.active)
  const mintableList = activeLists.find((l) => !!l.signedAllowance)
  const signedAllowance = mintableList?.signedAllowance
  const validator = mintableList?.list.validatorAuthorization

  const futureMintableNonPublicList = mintableList
    ? null
    : availableLists.find(
        (l) =>
          l.list.startsAt > now / 1000 && !!l.signedAllowance && !l.isPublic
      )

  const staticContract = useStaticContract()
  const { address } = useAccountAutoconnect()
  const windowHasFocus = useWindowHasFocus()

  const updateCount = () => {
    staticContract!
      .totalSupply()
      .then((res: BigNumber) => res.toString())
      .then((num: string) => parseFloat(num))
      .then((latestCount: number) => {
        if (count !== latestCount) {
          setCount(latestCount)
        }
      })
      .catch(unknownErrorHandler)
  }

  // do this once right away
  useEffect(() => {
    if (windowHasFocus || count === null) {
      console.log("Initial fetch for count")
      updateCount()
    }
  }, [windowHasFocus])
  // then continue to do it

  let interval: number | null = null
  if (windowHasFocus) {
    if (count && count >= TOTAL_COUNT) {
      // no need to check after sold out
      interval = null
    } else if (activeLists.length) {
      // check often when the mint is open
      interval = 972
    } else {
      // check infrequently before mint opens
      interval = 9721
    }
  }
  useInterval(updateCount, interval)

  useEffect(() => {
    if (address) {
      staticContract!
        .premintWalletMinted(address)
        .then((res: BigNumber) => res.toString())
        .then((num: string) => parseFloat(num))
        .then((latestMintedCount: number) => {
          if (mintedCount !== latestMintedCount) {
            setMintedCount(latestMintedCount)
          }
        })
        .catch(unknownErrorHandler)
    } else {
      setMintedCount(-1)
    }
  }, [staticContract, address])

  let { config } = usePrepareContractWrite({
    address: CONTRACT_ADDRESS,
    abi: ABI,
    // undefined here prevents the hook from throwing errors before we are ready to prepare it:
    functionName: signedAllowance && validator ? "premint" : undefined,
    args:
      signedAllowance && validator
        ? [
            {
              allowance: signedAllowance.allowance,
              allowanceSignature: signedAllowance.signature,
              validator: validator.validatorWallet,
              validatorAuthorizationSignature:
                validator.validatorAuthorizationSignature
            },
            1, // amount
            {
              gasLimit: 250000,
              value: signedAllowance?.allowance.unitPrice ?? "0"
            }
          ]
        : [],
    onError: unknownErrorHandler
  })

  const {
    data: mintData,
    write: mint,
    isLoading: isStartingMint
  } = useContractWrite(config)

  const [
    getPersistedTransactionHash,
    persistTransactionHash,
    removePersistedTransactionHash
  ] = useLocalStorage<`0x${string}`>(`transactionHash-${address}`)
  useEffect(() => {
    const hash = mintData?.hash
    if (hash) {
      persistTransactionHash(hash)
    }
  }, [mintData?.hash])

  const [persistedTransactionHash, setPersistedTransactionHash] = useState<
    `0x${string}` | null
  >(null)
  useEffect(() => {
    // Keep this value around even after we clear it from localStorage.
    setPersistedTransactionHash(getPersistedTransactionHash())
  }, [address])

  const {
    data: transactionData,
    isSuccess: isTransactionFinished,
    isLoading: isWaitingForTransaction
  } = useWaitForTransaction({
    hash: persistedTransactionHash ?? mintData?.hash,
    onError: errorHandler,
    onSuccess: transactionCompleteHandler,
    onSettled: removePersistedTransactionHash
  })
  const transactionSuccessful = transactionData?.status == 0x01

  const value = {
    activeList: activeLists.length ? activeLists[0].list : undefined,
    futureMintableNonPublicList: futureMintableNonPublicList?.list,
    mintableAllowance: mintableList
      ? mintableList.signedAllowance?.allowance
      : undefined,

    count: count ?? 0,
    mint,

    isLoading: count === null || mintedCount === null,
    isSoldOut: count !== null && count >= TOTAL_COUNT,
    hasMintedMax: !!mintedCount && mintedCount >= MAX_PER_WALLET,

    isMintOver: now > LAST_MINT_ENDS.toMillis(),

    isMinting: isStartingMint || isWaitingForTransaction,
    isSuccess: isTransactionFinished && transactionSuccessful
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

const TOAST_SETTINGS: ToastOptions = {
  position: "bottom-right",
  theme: "dark",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true
}

const txUrl = (transaction: ethers.providers.TransactionReceipt) => {
  const hash = transaction.transactionHash
  if (NETWORK_ID == 5) {
    return `https://goerli.etherscan.io/tx/${hash}`
  }
  return `https://etherscan.io/tx/${hash}`
}
const txLink = (transaction: ethers.providers.TransactionReceipt) => {
  return (
    <a target="_blank" href={txUrl(transaction)}>
      Info
    </a>
  )
}

const transactionCompleteHandler = (
  transaction: ethers.providers.TransactionReceipt
) => {
  if (transaction.status == 0x01) {
    toast(<span>Transaction Successful ({txLink(transaction)})</span>, {
      ...TOAST_SETTINGS,
      type: "success"
    })
  } else if (transaction.status == 0x00) {
    toast(<span>Transaction Failed ({txLink(transaction)})</span>, {
      ...TOAST_SETTINGS,
      type: "error"
    })
  } else {
    toast(<span>Something went wrong ({txLink(transaction)})</span>, {
      ...TOAST_SETTINGS,
      type: "warning"
    })
  }
}

const unknownErrorHandler = (err: Error) => {
  console.error("Error in contract call:", err)
}

const errorHandler = (err: Error) => {
  console.error(err)

  // @ts-ignore Metamask will include code with their error
  const code = err?.code || null
  if (code === 4001) {
    toast("Transaction Denied", {
      ...TOAST_SETTINGS,
      type: "warning"
    })
  } else {
    toast("Transaction Failed", {
      ...TOAST_SETTINGS,
      type: "error"
    })
  }
}
