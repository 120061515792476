import { useEffect, useState } from "react"

import { LISTS, PUBLIC_WALLET } from "../config"
import { AvailableList, ListConfiguration } from "../types"
import useAccountAutoconnect from "./useAccountAutoconnect"
import useNow from "./useNow"

export default function useLists() {
  const [lists, setLists] = useState<AvailableList[]>([])
  const now = useNow()
  const { address } = useAccountAutoconnect()

  useEffect(() => {
    const refreshLists = () => {
      const currentLists = LISTS.map((list) => ({
        active: now / 1000 >= list.startsAt && now / 1000 <= list.endsAt,
        signedAllowance: allowanceForList(address as string, list),
        isPublic: !!list.signedAllowances[PUBLIC_WALLET],
        list
      })).sort((a, b) => a.list.startsAt - b.list.startsAt)

      if (JSON.stringify(currentLists) !== JSON.stringify(lists)) {
        setLists(currentLists)
      }
    }

    // find current lists
    refreshLists()
    // refresh them continuously
    const timeout = setInterval(refreshLists, 1000)

    return () => clearTimeout(timeout)
  }, [now, address])

  return lists
}

function allowanceForList(wallet: string | undefined, list: ListConfiguration) {
  return (
    (wallet ? list.signedAllowances[wallet.toLowerCase()] : null) ??
    list.signedAllowances[PUBLIC_WALLET]
  )
}
